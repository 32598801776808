import * as React from 'react';
import './Layout.scss';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import backIcon from '../../../../assets/go-back.svg';

type LayoutProps = React.PropsWithChildren;

function Layout({ children }: LayoutProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const hideBackBtn = searchParams.get('source') === 'ms-teams';
  const isInEntryPoint = location.pathname === '/sos';

  const onBackButtonClick = () => {
    if (isInEntryPoint) {
      (window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ close: true }),
      );
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="sos-layout">
      <div className="sos-header">
        {!hideBackBtn && <div
          role="button"
          className="back-button"
          tabIndex={0}
          onClick={() => onBackButtonClick()}
        >
          <img src={backIcon} alt="go back" />
        </div>}
      </div>
      {children}
    </div>
  );
}

export default Layout;
