import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SOS from './SOS';
import SOSHelpline from './Helpline';
import Layout from './Layout';

function SOSHome() {
  return (
    <Layout>
      <Routes>
        <Route path="/helpline" element={<SOSHelpline />} />
        <Route path="/" element={<SOS />} />
      </Routes>
    </Layout>
  );
}
export default SOSHome;
