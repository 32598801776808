import * as React from 'react';
import Typography from '@widgets/Typography';
import Button from '@widgets/Button/Button';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import './Helpline.scss';
import { useFetch } from '@hooks/useFetch';
import { FetchKeys } from '@constants/queries';
import { IUser } from '@customTypes/response/IUser';
import Loader from '@widgets/Loader';
import { getUserDetail } from '@services/user';
import { tollFreeMsgs } from '@constants/sos';
import infoIcon from '../../../../assets/info-circle.svg';

interface LocationState {
  token: string;
  isIntellectEAP: boolean;
}

function SOSHelpline() {
  const { state } = useLocation();
  const { t } = useTranslation();
  const [helplines, setHelplines] = React.useState<any[]>([]);

  const { token: userToken, isIntellectEAP } = (state || {}) as LocationState;

  const { isLoading } = useFetch<IUser>(
    [FetchKeys.userDetails],
    () => getUserDetail(userToken),
    {
      refetchOnWindowFocus: false,
      onSuccess: (userData) => {
        setHelplines(
          userData.config.eapHelpLine
            ? userData.config.eapHelpLine.split(',').map((item: string) => ({
              title: [item.split(':')[0]?.trim()],
              cta: item.split(':')[1],
            }))
            : [],
        );
      },
    },
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="helpline-container">
      {isIntellectEAP && (
        <div className="helpline-header">
          <Typography size={20} weight="700" textAlign="center">
            {t('CONNECT_WITH_EAP')}
          </Typography>
        </div>
      )}
      <div className="helpline-items">
        {helplines.map((item: any) => (
          <div className="helpline-item" key={item.title}>
            <Typography size={16} weight="600" textAlign="center">
              {t(item.title)}
            </Typography>
            <Button
              height="40px"
              width="100%"
              onClick={() => {
                window.open(`tel:${item.cta}`);
              }}
              label={item.cta}
            />
            {item.cta in tollFreeMsgs ? (
              <section className="toll-free-info-container">
                <img src={infoIcon} alt="info-icon" />
                <Typography size={14} weight="400" color="trout">
                  {t(tollFreeMsgs[item.cta])}
                </Typography>
              </section>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SOSHelpline;
