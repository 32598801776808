import * as React from 'react';
import './SOS.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Typography from '@widgets/Typography';
import Button from '@widgets/Button/Button';
import { addItemToLocalStorage } from '@utilities/common/Storage';
import { StorageItems } from '@constants/App';
import Loader from '@widgets/Loader';
import { updateAppLanguage } from '../../../i18n';
import handshake from '../../../assets/handshake.png';
import { useUserDetail } from './hooks/useUserDetail';

interface SOSProps {
  sosTitle?: string;
}

function SOS(props: SOSProps) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { sosTitle } = props;
  const userToken = searchParams.get('token');
  const [organisationConfig, setOrganisationConfig] = React.useState<any>({});
  React.useEffect(() => {
    const locale = searchParams.get('locale');
    if (locale) {
      addItemToLocalStorage(StorageItems.APP_LANGUAGE, locale);
      updateAppLanguage();
    }
  }, []);

  const { isLoading } = useUserDetail(userToken || '', {
    onSuccess(data) {
      setOrganisationConfig({
        organisationId: data.memberships[0]?.organisationTeam.organisationId,
        ...data.config,
      });
    },
    onError: () => {
      setOrganisationConfig({});
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="sosContainer">
      <img src={handshake} className="sos-banner" alt="Handshake" />
      <div className="title-container">
        <Typography size={20} weight="700" textAlign="center">
          {t('NEED_TO_TALK')}
        </Typography>
        <Typography size={20} weight="700" textAlign="center">
          {t('GET_IMMEDIATE_HELP')}
        </Typography>
      </div>
      <div className="sos-description">
        <Typography
          size={14}
          weight="400"
          withColor="#7D8489"
          textAlign="center"
        >
          If you have suicidal thoughts or feel like you’re at breaking point,
          get immediate support from our 24/7 helplines
        </Typography>
      </div>
      <div className="sos-action-block">
        {!sosTitle && (
          <Typography size={12} weight="400" withColor="#7D8489">
            {t('FREE')}
          </Typography>
        )}
        <Button
          width="100%"
          variant="transparent"
          onClick={() =>
            navigate('/sos/helpline', {
              state: {
                isIntellectEAP: organisationConfig.helplineCTA !== 'company',
                token: searchParams.get('token'),
              },
            })
          }
          label={
            organisationConfig.helplineCTA === 'company'
              ? t('YOUR_COMPANY_EAP')
              : t('INTELLECT_HELPLINE_TITLE')
          }
        />
        <Button
          width="100%"
          variant="transparent"
          onClick={() => {
            window.open('https://intellect.co/mental-health-hotlines/');
          }}
          label={sosTitle || t('LOCAL_HOT_LINES')}
        />
        <div className="bottom-divider" />
      </div>
    </div>
  );
}

export default SOS;
